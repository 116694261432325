import {
  Button,
  Container,
  FormField,
  Header,
  Icon,
  Input,
  KeyValuePairs,
  Link,
  Modal,
  Pagination,
  Popover,
  SpaceBetween,
  Tabs,
  Wizard,
} from "@cloudscape-design/components";

import { useEffect, useState } from "react";
// @ts-ignore

import Definition from "../../images/famework-definition.svg";
import Organization from "../../images/framework-organization.svg";

import {
  RiAppsLine,
  RiArticleLine,
  RiBook3Line,
  RiBracesLine,
  RiBrainLine,
  RiBugLine,
  RiFileCodeLine,
  RiFileZipLine,
  RiFolder2Line,
  RiGitMergeLine,
  RiGroup2Line,
  RiIdCardLine,
  RiInfinityLine,
  RiKeyLine,
  RiPlugLine,
  RiPuzzleLine,
  RiRectangleFill,
  RiRectangleLine,
  RiSearchFill,
  RiSearchLine,
  RiServerLine,
  RiShieldUserLine,
  RiStickyNoteLine,
} from "@remixicon/react";
import { Viewer } from "../Viewer";
import { Col, Grid } from "@tremor/react";
import { useNavigate } from "react-router-dom";

import { Results } from "../../pages/landing/query_result";

import axios from "axios";
import { Editor } from "../Editor";
import HeroImage from "./HeroImage";
import ThemedImage from "./ThemedImage";
import gsap from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import useGSAPAnimation from "./scroller";

const cards2 = [
  {
    label: "Frameworks",
    icon: RiBook3Line,
  },
  {
    label: "Controls",
    icon: RiStickyNoteLine,
  },
  {
    label: "Integrations",
    icon: RiPuzzleLine,
  },
  {
    label: "Roles",
    icon: RiShieldUserLine,
  },
  {
    label: "SSO",
    icon: RiIdCardLine,
  },
  {
    label: "API",
    icon: RiPlugLine,
  },
  {
    label: "Git",
    icon: RiGitMergeLine,
  },
  {
    label: "Queries",
    icon: RiSearchLine,
  },
  {
    label: "Views",
    icon: RiRectangleLine,
  },

  {
    label: "Parameters",
    icon: RiBracesLine,
  },
  {
    label: "Policies",
    icon: RiServerLine,
  },

  {
    label: "Tasks",
    icon: RiAppsLine,
  },
];

const URLS = [
  "https://tour.opencomply.io/embed/cm5q1ezs60ljjpelfs3zkfzdn?embed_v=2",
  "https://tour.opencomply.io/embed/cm5u6d2uf0eixx30ie910b8ad?embed_v=2",
  "https://tour.opencomply.io/embed/cm5u7317k0ex2x30igug7nxg8?embed_v=2",
];

export default function UseCaseNew2() {
  const [discoverOption, setDiscoverOption] = useState(-1);
  const [open, setOpen] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [yaml, setYaml] = useState({});
  const [yaml1, setYaml1] = useState({});

  const [video, setVideo] = useState(0);
  const [width, setWidth] = useState({
    0: 0,
    1: 0,
    2: 0,
  });
  const [time, setTime] = useState(0);
  const [selectedCard, setSelectedCard] = useState("");

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      // @ts-ignore
      const video_dur = document.getElementById("hero-video")?.duration;
      if (time >= video_dur) {
        setVideo((video + 1) % 2);
        clearInterval(countdownInterval);
        setWidth({
          0: 0,
          1: 0,
          2: 0,
        });
        setTime(0);
      } else {
        setTime(time + 0.5);
        // @ts-ignore
        const old = width;
        // @ts-ignore

        old[video] = (time / video_dur) * 100;
        setWidth(old);
      }
    }, 500);

    return () => clearInterval(countdownInterval);
  }, [time, video]);

  useEffect(() => {
    if (discoverOption != -1) {
      // setOpen(true);
    }
  }, [discoverOption]);
  const navigate = useNavigate();

  const GetYaml = (url: string, flag: boolean, card: string) => {
    axios
      .get(url)
      .then((resp) => {
        if (flag) {
          setYaml({ ...yaml, [card]: resp.data });
        } else {
          setYaml1({ ...yaml1, [card]: resp.data });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const GetSteps = (card: string) => {
    const step: any = [];

    switch (card) {
      case "Frameworks":
        step.push({
          title: "Introduction",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-lg font-semibold">
                  opencomply lets you specify Compliance Framework in YAML.
                </span>
                <span className="text-base ">Here's an example:</span>
                <Editor
                  height={window.innerWidth > 750 ? 400 : 150}
                  // @ts-ignore
                  obj={yaml[card]}
                />
              </div>
            </>
          ),
        });
        step.push({
          title: "Definition",
          content: (
            <>
              <div className="flex flex-col gap-3 w-full">
                <span className="text-base ">
                  Here's how you Frameworks are defined:
                </span>
                <div className="rounded-2xl w-fit mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-fit  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    {" "}
                    <img
                      src={Definition}
                      className=" sm:min-h-[400px] sm:min-w-[350px]"
                    />
                  </div>
                </div>
              </div>
            </>
          ),
        });
        step.push({
          title: "Organization",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  Here's how Frameworks are organized:
                </span>
                <ul className=" list-disc list-inside ">
                  <li className="mt-2">
                    <b>Compliance Framework:&nbsp;</b>The top-level entity that
                    organizes all Control Groups and their Controls.
                  </li>
                  <li className="mt-2">
                    <b>Control Group 1 and 2:&nbsp;</b> Logical groupings (like
                    folders) under the Compliance Framework.
                  </li>
                  {/* <li className="mt-2">
                     <b>Subgroup (Control Group 1.1): &nbsp;</b>Nested under
                     Control Group 1 for more granular organization.
                   </li> */}
                  <li className="mt-2">
                    <b>Controls: &nbsp;</b>Individual compliance checks or
                    requirements within each group.
                  </li>
                </ul>
                <div className="rounded-2xl w-fit mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-fit  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <img src={Organization} className="w-fit h-full" />
                  </div>
                </div>
              </div>
            </>
          ),
        });
        break;

      case "Controls":
        step.push({
          title: "Controls",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  Controls represent specific compliance requirements or best
                  practices to assess. Controls are defined in YAML.
                </span>
                <span className="text-base">
                  A compliance rule has two parts:
                </span>
                <ol className=" list-decimal list-inside  ">
                  <li className="mt-2">
                    <b>Metadata:&nbsp;</b>ID, Title, Description (Optional),
                    Severity
                  </li>
                  <li className="mt-2">
                    <b>Technical Logic: &nbsp;</b> Instructions on how to verify
                    that the control is met
                  </li>
                </ol>
                {/* <div className="rounded-2xl w-full mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                   <div className="rounded-xl w-full  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                     <img src={Organization} className="w-fit h-full" />
                   </div>
                 </div> */}
              </div>
            </>
          ),
        });
        step.push({
          title: "Inline Policies",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base ">
                  Controls with inline policies contain both the compliance
                  requirement (what must be done) and the technical logic (how
                  to check it) in a single YAML file.
                </span>
                <Editor
                  height={window.innerWidth > 750 ? 400 : 150}
                  // @ts-ignore
                  obj={yaml[card]}
                />
              </div>
            </>
          ),
        });
        step.push({
          title: "Reference Policies",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  To improve reusability, controls can reference other policies.
                  These are called "Controls with Referenced Policies." This
                  allows for reusability, enables customization, and ensures
                  consistency.
                </span>
                <span className="text-base ">
                  Here's a Control reusing a policy.
                </span>
                <Editor
                  height={window.innerWidth > 750 ? 400 : 150}
                  // @ts-ignore
                  obj={yaml1[card]}
                />
              </div>
            </>
          ),
        });
        break;

      case "Integrations":
        step.push({
          title: "Plugins",
          content: (
            <>
              <div className="flex flex-col gap-3 w-full">
                <span className="text-bold text-lg ">
                  OpenComply leverages a modular integration mechanism through
                  Plugins.
                </span>
                <span className="text-base ">
                  Plugins extend OpenComply's capabilities by providing
                  visibility into various workloads, including infrastructure,
                  data stores, configurations, and<b> any technical item</b>{" "}
                  accessible via APIs.
                </span>
                <div className="rounded-2xl w-fit mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-fit  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    {" "}
                    <img
                      src={
                        "https://content.opencomply.io/website/architecture/plugins-and-integration.png"
                      }
                      className=" sm:min-h-[400px] sm:min-w-[350px]"
                    />
                  </div>
                </div>
              </div>
            </>
          ),
        });
        break;

      case "Roles":
        step.push({
          title: "Role-Based Access Control (RBAC)",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  Community Edition includes three built-in roles:
                </span>
                <ul className=" list-disc list-inside ">
                  <li className="mt-2">
                    <b>Administrator:&nbsp;</b>Full administrative access,
                    including IAM, permissions, and API key management.
                  </li>
                  <li className="mt-2">
                    <b>Editor:&nbsp;</b> View all inventory and compliance data,
                    run audits, but cannot modify compliance or application
                    settings.
                  </li>

                  <li className="mt-2">
                    <b>Viewer: &nbsp;</b>View all inventory and compliance data,
                    including running queries, but cannot modify data or execute
                    actions. Excluded from viewing or modifying Roles &
                    Permissions.
                  </li>
                </ul>
              </div>
            </>
          ),
        });
        step.push({
          title: "Assign Roles",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  Easily Create Users and Assign Roles :
                </span>

                <div className="rounded-2xl w-fit flex justify-center items-center mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-fit  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <img
                      src={
                        "https://content.opencomply.io/website/product-screenshots/create-new-user-with-role.png"
                      }
                      className="w-fit h-full"
                    />
                  </div>
                </div>
              </div>
            </>
          ),
        });

        break;

      case "SSO":
        step.push({
          title: "Single Sign-On (SSO) out-of-the-box",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  All editions of Opencomply include Single Sign-On (SSO)
                  out-of-the-box. Enable SSO with any OIDC provider, such as
                  Google Workspace, Okta, and Azure AD.
                </span>

                <div className="rounded-2xl w-fit mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-fit  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <img
                      src={
                        "https://content.opencomply.io/website/product-screenshots/setup-sso.png"
                      }
                      className="w-fit h-full"
                    />
                  </div>
                </div>
              </div>
            </>
          ),
        });
        break;

      case "API":
        step.push({
          title: "Built-in API Support",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  All editions of Opencomply include API support. Embed security
                  and compliance into your existing workflows, such as DevOps
                  pipelines and change processes.
                </span>

                <div className="rounded-2xl w-fit mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-fit  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <img
                      src={
                        "https://content.opencomply.io/website/product-screenshots/create-new-api-key.png"
                      }
                      className="w-fit h-full"
                    />
                  </div>
                </div>
              </div>
            </>
          ),
        });
        break;

      case "Git":
        step.push({
          title: "Reliable Metadata: Git-Based Version Control",
          content: (
            <>
              <div className="flex flex-col gap-3">
                <span className="text-base">
                  Opencomply utilizes Git for version control of all platform
                  metadata, ensuring every change is version-controlled,
                  auditable, and easily maintainable.
                </span>

                <div className="rounded-2xl w-fit mt-2 bg-slate-50/40 p-2 ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-fit  bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <img
                      src={
                        "https://content.opencomply.io/website/product-screenshots/git-support.png"
                      }
                      className="w-fit h-full"
                    />
                  </div>
                </div>
              </div>
            </>
          ),
        });
        break;

      default:
        break;
    }

    return step;
  };
  const GetYamls = (card: string) => {
    switch (card) {
      case "Frameworks":
        GetYaml(
          "https://raw.githubusercontent.com/opengovern/platform-configuration/refs/heads/main/compliance/frameworks/baseline/efficiency.yaml",
          true,
          card
        );

        break;

      case "Controls":
        GetYaml(
          "https://raw.githubusercontent.com/opengovern/platform-configuration/refs/heads/main/compliance/controls/aws/aws_acm_certificate_not_expired.yaml",
          true,
          card
        );
        GetYaml(
          "https://raw.githubusercontent.com/opengovern/platform-configuration/refs/heads/main/compliance/controls/baseline/aws/IAM/aws_access_keys_rotated_x_days.yaml",
          false,
          card
        );

        break;

      default:
        break;
    }
  };

  const GetStepsContent = (card: string) => {
    switch (card) {
      case "Frameworks":
        return true;
        break;

      case "Controls":
        return true;
        break;

      case "Integrations":
        return true;
        break;

      case "Roles":
        return true;

        break;

      case "SSO":
        return true;
        break;

      case "API":
        return true;
        break;

      case "Git":
        return true;
        break;

      default:
        break;
    }

    return false;
  };
  const container = useRef(null);
  const container1 = useRef(null);

  useGSAPAnimation(container, "cards-section", "pin-section", "card");
  useGSAPAnimation(container1, "cards-section1", "pin-section1", "card1");

  return (
    <>
      <section
        aria-labelledby="code-example-title"
        className="mx-auto   mt-4 w-full max-w-6xl 2xl:max-w-7xl px-3 flex flex-col justify-center items-center"
      >
        <h2
          id="code-example-title"
          className="mt-2 inline-block bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 text-3xl font-semibold tracking-tighter text-transparent sm:text-5xl md:text-5xl dark:from-gray-50 dark:to-gray-300"
        >
          Secure Your Entire Tech Stack
        </h2>
        <p className="sm:mt-6 sm:inline hidden mt-4 max-w-2xl text-lg tracking-tighter text-gray-800 dark:text-gray-400 px-2">
          OpenComply makes security & compliance radically simple.
        </p>
      </section>

      <section
        aria-labelledby="code-example-title"
        className="mx-auto sm:mt-28 mt-8 w-full max-w-6xl 2xl:max-w-7xl flex sm:flex-row flex-col sm:p-0 p-6 gap-4 justify-between"
      >
        <div className="w-full max-w-sm relative  ">
          <div className=" sticky top-80 mt-60 w-full sm:pb-80 pb-0 ">
            <div className="flex w-full items-center justify-between space-x-2 mb-1">
              <div className="font-semibold text-slate-900 w-full  dark:text-white sm:text-3xl text-4xl">
                Connect Platforms
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="rounded-2xl w-full bg-slate-50/40 p-2 h-fullring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
            <div className="rounded-xl w-full bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
              <ThemedImage
                lightSrc={
                  "https://content.opencomply.io/website/product-screenshots/1_1.png"
                }
                darkSrc={
                  "https://content.opencomply.io/website/product-screenshots/1_1.png"
                }
                alt="A preview of the Database web app"
                width={2400}
                height={1600}
                className="rounded-xl shadow-2xl dark:shadow-indigo-600/10"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        aria-labelledby="code-example-title"
        className="mx-auto      h-[250vh]  w-full  max-w-6xl  2xl:max-w-7xl flex sm:flex-row flex-col sm:p-0 p-6 gap-4 justify-between"
      >
        <div className="w-full max-w-sm relative top-[32rem] h-[70%]  ">
          <div className=" sticky top-[30rem] mt-[12rem] w-full">
            <div className="flex w-full items-center justify-between space-x-2 mb-1">
              <div className="font-semibold text-slate-900 w-full  dark:text-white sm:text-3xl text-2xl">
                Establish Guardrails
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full pin-section mt-[16rem]   ">
          <div
            ref={container}
            className="section    cards-section pt-[32rem]     w-full    "
          >
            <div className="cards w-full   ">
              <div className="card h-full ">
                {" "}
                <div className="rounded-2xl w-full bg-slate-50/40 p-2 h-fullring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-full bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <ThemedImage
                      lightSrc={
                        "https://content.opencomply.io/website/product-screenshots/2_1.png"
                      }
                      darkSrc={
                        "https://content.opencomply.io/website/product-screenshots/2_1.png"
                      }
                      alt="A preview of the Database web app"
                      width={2400}
                      height={1600}
                      className="rounded-xl shadow-2xl dark:shadow-indigo-600/10"
                    />
                  </div>
                </div>
              </div>
              <div className="card gradient-orange h-full">
                {" "}
                <div className="rounded-2xl w-full bg-slate-50/40 p-2 h-fullring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-full bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <ThemedImage
                      lightSrc={
                        "https://content.opencomply.io/website/product-screenshots/2_1.png"
                      }
                      darkSrc={
                        "https://content.opencomply.io/website/product-screenshots/2_1.png"
                      }
                      alt="A preview of the Database web app"
                      width={2400}
                      height={1600}
                      className="rounded-xl shadow-2xl dark:shadow-indigo-600/10"
                    />
                  </div>
                </div>
              </div>
              <div className="card gradient-red h-full ">
                {" "}
                <div className="rounded-2xl w-full bg-slate-50/40 p-2 h-full ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-full bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <ThemedImage
                      lightSrc={
                        "https://content.opencomply.io/website/product-screenshots/2_2.png"
                      }
                      darkSrc={
                        "https://content.opencomply.io/website/product-screenshots/2_2.png"
                      }
                      alt="A preview of the Database web app"
                      width={2400}
                      height={1600}
                      className="rounded-xl shadow-2xl dark:shadow-indigo-600/10"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        aria-labelledby="code-example-title"
        className="mx-auto sm:p-0  h-[250vh]       w-full  max-w-6xl  2xl:max-w-7xl flex sm:flex-row flex-col  p-6 gap-4 justify-between"
      >
        <div className="w-full max-w-sm relative top-[32rem] h-[70%]  ">
          <div className=" sticky top-[32rem] mt-[12rem] w-full">
            <div className="flex w-full items-center justify-between space-x-2 mb-1">
              <div className="font-semibold text-slate-900 w-full  dark:text-white sm:text-3xl text-2xl">
                Verify Compliance
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full pin-section1 mt-[16rem]   ">
          <div
            ref={container1}
            className="section    cards-section1 pt-[32rem]     w-full    "
          >
            <div className="cards1 w-full   ">
              <div className="card1 h-full gradient-green bg-red-50">
                {" "}
                <div className="rounded-xl  flex flex-col gap-4 ">
                  <div className="rounded-xl bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <video
                      id="hero-video"
                      className="rounded-xl w-full shadow-2xl dark:shadow-indigo-600/10"
                      autoPlay
                      loop
                      muted
                      playsInline
                      preload="auto"
                      // @ts-ignore
                      src={
                        "https://content.opencomply.io/website/product-videos/run_audit.mp4"
                      }
                    >
                      <source
                        src={
                          // @ts-ignore
                          "https://content.opencomply.io/website/product-videos/run_audit.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
              <div className="card1 gradient-orange bg-red-100">
                {" "}
                <div className="rounded-2xl w-full bg-slate-50/40 p-2 h-fullring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-full bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <ThemedImage
                      lightSrc={require("../../images/image.png")}
                      darkSrc={require("../../images/image.png")}
                      alt="A preview of the Database web app"
                      width={2400}
                      height={1600}
                      className="rounded-xl shadow-2xl dark:shadow-indigo-600/10"
                    />
                  </div>
                </div>
              </div>
              <div className="card1 gradient-red bg-red-200">
                {" "}
                <div className="rounded-2xl w-full bg-slate-50/40 p-2 h-full ring-1 ring-inset ring-slate-200/50 dark:bg-gray-900/70 dark:ring-white/10">
                  <div className="rounded-xl w-full bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
                    <ThemedImage
                      lightSrc={require("../../images/image.png")}
                      darkSrc={require("../../images/image.png")}
                      alt="A preview of the Database web app"
                      width={2400}
                      height={1600}
                      className="rounded-xl shadow-2xl dark:shadow-indigo-600/10"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        aria-labelledby="code-example-title"
        className="mx-auto sm:mt-60 mt-8 w-full max-w-6xl  2xl:max-w-7xl flex sm:flex-row flex-col sm:p-0 p-6 gap-4 justify-between"
      >
        <div className="w-full max-w-md relative ">
          <div className=" sticky top-80">
            <div className="flex w-full items-center justify-between space-x-2 mb-1">
              <div className="font-semibold text-slate-900 w-full  dark:text-white sm:text-3xl text-2xl">
                Customize opencomply
              </div>
            </div>

            <div className="text-slate-900 dark:text-white mt-4">
              <div className="flex flex-col gap-5">
                <div className="text-slate-900 dark:text-white w-full text-xl ">
                  {" "}
                  Let opencomply work for you. Here's how we make it happen.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  flex flex-col gap-10 text-black">
          <div className="rounded-xl   flex flex-col gap-4 ">
            <div className="flex justify-center w-full">
              <Grid
                numItems={2}
                numItemsMd={4}
                numItemsSm={4}
                className=" justify-between gap-4"
              >
                <>
                  {cards2
                    ?.slice(0, window.innerWidth < 738 ? 4 : cards2.length)
                    ?.map((card, index) => {
                      return (
                        <>
                          {GetStepsContent(card.label) ? (
                            <>
                              {" "}
                              <Col
                                className="w-full h-full cursor-pointer"
                                onClick={() => {
                                  if (GetStepsContent(card.label)) {
                                    setOpen(true);
                                    setSelectedCard(card.label);
                                    GetYamls(card.label);
                                  }
                                }}
                              >
                                <div className="flex flex-col p-4 rounded-xl justify-center items-center gap-3 bg-slate-300 hover:bg-slate-400 cursor-pointer dark:bg-slate-900 hover:dark:bg-slate-950 ">
                                  {
                                    <card.icon
                                      color=""
                                      className="card-icons"
                                      size={35}
                                    />
                                  }

                                  <span className="text-black dark:text-white text-base">
                                    {card.label}
                                  </span>
                                </div>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col
                                className="w-full h-full cursor-pointer"
                                onClick={() => {
                                  if (GetStepsContent(card.label)) {
                                    setOpen(true);
                                    setSelectedCard(card.label);
                                  }
                                }}
                              >
                                <Popover
                                  dismissButton={false}
                                  className="w-full h-full cursor-pointer grid "
                                  position="top"
                                  size="small"
                                  triggerType="custom"
                                  content="Content Coming Soon ..."
                                >
                                  <div className="flex w-full flex-col p-4 rounded-xl justify-center items-center gap-3 bg-slate-300 hover:bg-slate-400 cursor-pointer dark:bg-slate-900 hover:dark:bg-slate-950 ">
                                    {
                                      <card.icon
                                        color=""
                                        className="card-icons"
                                        size={35}
                                      />
                                    }

                                    <span className="text-black dark:text-white text-base">
                                      {card.label}
                                    </span>
                                  </div>
                                </Popover>
                              </Col>
                            </>
                          )}
                        </>
                      );
                    })}
                </>
              </Grid>
            </div>
          </div>
        </div>
      </section>

      <Modal
        size="max"
        visible={open}
        onDismiss={() => {
          setOpen(false);
          setActiveStepIndex(0);
        }}
        header={selectedCard}
        className="p-2"
      >
        <Wizard
          i18nStrings={{
            stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
              `Step ${stepNumber} of ${stepsCount}`,
            skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
            navigationAriaLabel: "Steps",
            cancelButton: "",
            previousButton: "Previous",
            nextButton: "Next",
            submitButton: "Finish",
            optional: "optional",
          }}
          onNavigate={({ detail }) => {
            setActiveStepIndex(detail.requestedStepIndex);
          }}
          className=""
          activeStepIndex={activeStepIndex}
          onCancel={() => {
            setOpen(false);
            setActiveStepIndex(0);
          }}
          onSubmit={() => {
            setOpen(false);
            setActiveStepIndex(0);
          }}
          steps={GetSteps(selectedCard)}
        />
      </Modal>
    </>
  );
}
