import React, { useState } from "react";
import { Badge } from "../Badge";
// @ts-ignore
import video from "../../videos/2024-10-08-How_to_Customize_Controls.mp4";
import CopyToClipboard from "../CopyToClipboard";
import { Button, Modal, Popover } from "@cloudscape-design/components";
import { RiExternalLinkLine, RiInformationLine } from "@remixicon/react";
import { Flex } from "@tremor/react";
import Cal from "@calcom/embed-react";

const DISCOVER_URLS = [
  "https://www.youtube.com/embed/ZK-rNEhJIDs",
  "https://www.youtube.com/embed/BY2mTMBkuFI",
  "https://www.youtube.com/embed/joiyb6c_Ry4",
];

export default function Steps({setOpen} :any) {
  return (
    <>
      <section
        aria-labelledby="features-title"
        className="mx-auto sm:mt-24 mt-4 w-full mb-16 max-w-6xl 2xl:max-w-7xl px-3 flex flex-col justify-center items-center"
      >
        {/* <Badge>How it works</Badge> */}
        <h2
          id="features-title"
          className="mt-2 mb-5 inline-block dark:text-white bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 text-3xl font-bold tracking-tighter text-transparent sm:text-3xl md:text-3xl dark:from-gray-50 dark:to-gray-300"
        >
          Start governing every layer of your tech stack in under 10 mins
        </h2>

        <Flex className=" sm:flex-row flex-col sm:gap-20 gap-5 justify-center items-center ">
          <div className="space-y-8 sm:w-[80%] w-full  relative before:absolute  ">
            <div className="relative flex gap-5 flex-wrap items-center justify-center md:justify-center md:odd:flex-row group is-active">
              <div className="w-[calc(100%)]     rounded">
                <div className="w-full">
                  {/* code to copy */}
                  <p className=" mb-6 max-w-3xl text-lg leading-7 gap-2 text-gray-900 dark:text-gray-400 flex justify-center items-center">
                    Deploy <strong>opencomply</strong> on Kubernetes with helm
                    <Popover
                      dismissButton={false}
                      position="top"
                      className="mt-1"
                      size="small"
                      triggerType="custom"
                      content={
                        <span>
                          Requires Kubernetes v1.29+ with minimum of 3 nodes (2
                          vCPUs/8 GB RAM).
                        </span>
                      }
                    >
                      <RiInformationLine />
                    </Popover>
                  </p>
                  <div className="flex  flex-row gap-2 mt-2 w-full relative">
                    <div className="p-5 border dark:border-white  bg-slate-300 dark:bg-gray-900 rounded-xl w-full">
                      <code className=" text-[14px]  text-black dark:text-white">
                        <span className="text-[#8250df]">helm</span> repo add
                        opencomply https://charts.opencomply.io --force-update
                        <br />
                        <span className="text-[#8250df]">helm</span> install -n
                        opencomply opencomply opencomply/opencomply
                        --create-namespace --timeout=10m
                        <br />
                        <span className="text-[#8250df]"> kubectl</span>{" "}
                        port-forward -n opencomply svc/nginx-proxy 8080:80
                      </code>
                    </div>
                    <div className=" absolute right-2 top-2  [grid-area:2/1] z-[2] justify-self-end backdrop-blur-md leading-none self-start  text-dark-tremor-brand-subtle  rounded-md    print:hidden">
                      <CopyToClipboard
                        code={`helm repo add opencomply https://charts.opencomply.io
helm repo update 
helm install -n opencomply opencomply
opencomply/opencomply --create-namespace --timeout=10m
kubectl port-forward -n opencomply svc/nginx-proxy 8080:80`}
                      />{" "}
                    </div>
                  </div>
                  <div className="flex text-sm flex-wrap flex-1 justify-start md:justify-center flex-row gap-2 mt-4 ">
                    <a
                      target="__blank"
                      href="https://docs.opencomply.io/oss/advanced-setup/deploy-to-aws"
                      className={`p-2 mx-2 px-4 border flex flex-row gap-2 items-center justify-center dark:border-white text-black dark:text-white  rounded-3xl cursor-pointer sm:hover:dark:bg-white sm:hover:bg-blue-950 sm:hover:text-white sm:hover:dark:text-black    `}
                    >
                      <span>AWS</span>
                      <RiExternalLinkLine />
                    </a>

                    <a
                      target="__blank"
                      href="https://docs.opencomply.io/oss/advanced-setup/deploy-to-azure"
                      className={`p-2 mx-2 px-4 border flex flex-row gap-2 items-center justify-center dark:border-white text-black dark:text-white  rounded-3xl cursor-pointer sm:hover:dark:bg-white sm:hover:bg-blue-950 sm:hover:text-white sm:hover:dark:text-black    `}
                    >
                      <span>Azure</span>
                      <RiExternalLinkLine />
                    </a>
                    <a
                      target="__blank"
                      href="https://docs.opencomply.io/oss/advanced-setup/deploy-on-digitalocean-linode"
                      className={`p-2 mx-2 px-4 border flex flex-row gap-2 items-center justify-center dark:border-white text-black dark:text-white  rounded-3xl cursor-pointer sm:hover:dark:bg-white sm:hover:bg-blue-950 sm:hover:text-white sm:hover:dark:text-black    `}
                    >
                      <span>DigitalOcean</span>
                      <RiExternalLinkLine />
                    </a>
                    <a
                      target="__blank"
                      href="https://docs.opencomply.io/oss/advanced-setup/deploy-to-google-cloud-gke"
                      className={`p-2 mx-2 px-4 border flex flex-row gap-2 items-center justify-center dark:border-white text-black dark:text-white  rounded-3xl cursor-pointer sm:hover:dark:bg-white sm:hover:bg-blue-950 sm:hover:text-white sm:hover:dark:text-black    `}
                    >
                      <span>GKE</span>
                      <RiExternalLinkLine />
                    </a>
                    <a
                      target="__blank"
                      href="https://docs.opencomply.io/oss/advanced-setup/deploy-on-digitalocean-linode"
                      className={`p-2 mx-2 px-4 border flex flex-row gap-2 items-center justify-center dark:border-white text-black dark:text-white  rounded-3xl cursor-pointer sm:hover:dark:bg-white sm:hover:bg-blue-950 sm:hover:text-white sm:hover:dark:text-black    `}
                    >
                      <span>Linode</span>
                      <RiExternalLinkLine />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="  flex-col sm:flex hidden justify-center items-center">
            <div
              id="or-sprator"
              className="p-3 relative border-indigo-500 border-2 rounded-[100%] text-black dark:text-white"
            >
              OR
            </div>
          </div>
          <div className="flex sm:w-[20%] w-full flex-col gap-3 items-center justify-center">
            <span className="text-black dark:text-white text-xl text-center w-full dark:text-whte font-semibold">
              Deploy for production
            </span>
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              Try cloud for free
            </Button>
          </div>
        </Flex>
      </section>
      
    </>
  );
}
