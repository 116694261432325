export const FAQ = {
  sectionTitle: "Frequently Asked Questions (FAQ)",
  sectionSubtitle: "Do you have any questions? Your answer might be below!",
  faq: [
    {
      order: 1,
      "question-title": "What is opencomply?",
      content:
        "opencomply is a security and compliance platform that automates discovery, stores data historically, and fosters collaboration. It’s open-source at its core, with commercial editions for advanced features.",
      url: [
        {
          Label: "Details",
          URL: "https://docs.opencomply.io/oss/platform/faq#id-1.-what-is-opencomply",
        },
      ],
    },
    {
      order: 2,
      "question-title":
        "How is opencomply different from Steampipe or CloudQuery?",
      url: null,
      content:
        "Unlike Steampipe or CloudQuery, opencomply adds a UI, robust API, stateful data with historical snapshots, controls, and policy-as-code or query (SQL/Rego). It’s designed for collaboration, time-based auditing, and fine-grained security controls.",
    },
    {
      order: 3,
      "question-title":
        "What are the key differences between the Community and paid versions?",
      url: [
        {
          Label: "License",
          URL: "https://opencomply.io/license",
        },
        {
          Label: "License FAQ",
          URL: "https://opencomply.io/license-faq",
        },
      ],
      content:
        "Community is open-source (BSL v1.1) and self-hosted with basic features. Paid tiers add dashboards, data controls, custom roles, event-driven actions, seamless upgrades, extended audit history, flexible hosting, a commercial license, and premium plugins.",
    },
    {
      order: 4,
      "question-title": "What is the tech stack?",
      url: null,
      content:
        "opencomply uses Go (backend) and TypeScript (frontend), running on Kubernetes with KEDA for scaling. Data goes in PostgreSQL (config) and OpenSearch (evidence/audits). Git stores metadata; NATS for messaging; Vault for credentials. Community code is on GitHub.",
    },
    {
      order: 5,
      "question-title": "Are Steampipe plugins compatible with opencomply?",
      url: null,
      content:
        "Not directly, but they can be ported. opencomply uses Vault for credentials, schedules with Kubernetes/KEDA, and thus differs from Steampipe’s structure. Plugins require adaptation but can be migrated without major effort.",
    },
    {
      order: 6,
      "question-title": "Is opencomply a CSPM?",
      url: null,
      content:
        "opencomply can fully replace traditional CSPMs (e.g., Datadog, Prisma) by offering core posture management plus extended PaaS coverage, broader integrations, and more flexible governance and automation than typical CSPM tools.",
    },
    {
      order: 7,
      "question-title":
        "Does opencomply depend on cloud-native security services like AWS Config or Azure Security Center?",
      url: null,
      content:
        "No. opencomply can replace services like AWS Config or Azure Security Center. It unifies multiple data sources to provide comprehensive coverage without relying on native cloud security tools.",
    },
    {
      order: 8,
      "question-title": "Where is data stored?",
      url: null,
      content:
        "opencomply stores product metadata in Git, configurations in PostgreSQL, and evidence/audit trails in OpenSearch. The Community edition deploys these databases in Kubernetes by default. SaaS customers have encrypted, isolated data managed by opencomply.",
    },
  ],
};
  