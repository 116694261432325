"use client"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../Accordion"
import { FAQ } from "../../pages/landing/faq";
import ReactMarkdown from "react-markdown";
import Balancer from "react-wrap-balancer";
import { useMDXComponents } from "../../components/mdx-components.js";
import rehypeRaw from "rehype-raw";
import { Flex } from "@tremor/react";
import Badge from "../Badge";
import { RiExternalLinkLine } from "@remixicon/react";
import { useState } from "react";

export function Faqs() {
  const [show,setShow] = useState(4)
  return (
    <section
      className="mx-auto  mt-28 max-w-6xl 2xl:max-w-7xl p-1 px-2 sm:mt-28"
      aria-labelledby="faq-title"
    >
      <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-14">
        <div className="col-span-full sm:col-span-5">
          <h2
            id="faq-title"
            className="inline-block scroll-my-24 bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 pr-2 text-2xl font-bold tracking-tighter text-transparent lg:text-3xl dark:from-gray-50 dark:to-gray-300"
          >
            {FAQ.sectionTitle}
          </h2>
          <p className="mt-4 text-base leading-7 text-gray-600 dark:text-gray-400">
            {FAQ.sectionSubtitle}
            {/* Don&rsquo;t
            hesitate to get in touch with our{" "}
            <a
              href="#"
              className="font-medium text-indigo-600 hover:text-indigo-300 dark:text-indigo-400"
            >
              customer support
            </a>{" "}
            team. */}
          </p>
        </div>
        <div className="col-span-full mt-6 lg:col-span-7 lg:mt-0 transition-all duration-300">
          <Accordion type="multiple" className="mx-auto  ">
            {FAQ.faq
              .sort((a, b) => a.order - b.order)
              .slice(0, show)
              .map((item) => (
                <AccordionItem
                  value={item["question-title"]}
                  key={item["question-title"]}
                  className="py-3 first:pb-3 first:pt-0"
                >
                  <AccordionTrigger>{item["question-title"]}</AccordionTrigger>
                  <AccordionContent className="text-gray-600 dark:text-gray-400">
                    <ReactMarkdown
                      // @ts-ignore
                      components={useMDXComponents({})}
                      // @ts-ignore

                      children={item["content"]}
                      skipHtml={false}
                      rehypePlugins={[rehypeRaw]}
                    />
                    {item?.url && item?.url.length > 0 && (
                      <>
                        <Balancer />
                        <Flex className="mt-2 flex-row flex-wrap gap-2 justify-start items-start">
                          <>
                            {item.url?.map((url) => {
                              return (
                                <>
                                  <Badge className="">
                                    <a
                                      href={url.URL}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="flex flex-row items-center text-indigo-600 justify-start gap-2"
                                    >
                                      {url.Label}
                                      <RiExternalLinkLine className="inline-block ml-1" />
                                    </a>
                                  </Badge>
                                </>
                              );
                            })}
                          </>
                        </Flex>
                      </>
                    )}
                  </AccordionContent>
                </AccordionItem>
              ))}
          </Accordion>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => {
                if (show == FAQ.faq.length) {
                  setShow(4);
                } else {
                  setShow(FAQ.faq.length);
                }
              }}
              className=" text-gray-800  dark:text-gray-50 px-4 py-2 rounded-md"
            >
              {show == FAQ.faq.length ? "- show less" : "+ show  more"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
