export const Results = {
  results: 33,
  image_list: [
    { image: "scratch", count: 14 },
    { image: "docker.io/golang:alpine", count: 14 },
    { image: "cloudql-plugin-base:0.0.1", count: 6 },
    { image: "golang:1.23-alpine", count: 4 },
    { image: "alpine:latest", count: 3 },
    { image: "alpine:edge", count: 2 },
    { image: "ubuntu:20.04", count: 2 },
    { image: "node:18-alpine", count: 2 },
    { image: "node:22-alpine", count: 1 },
    { image: "postgres:14.13", count: 1 },
    { image: "postgres:17.2", count: 1 },
    { image: "cloudql-plugin-aws:0.0.1", count: 1 },
    { image: "cloudql-plugin-azure:0.0.1", count: 1 },
    { image: "cloudql-plugin-cloudflare:0.0.1", count: 1 },
    { image: "cloudql-plugin-cohereai:0.0.1", count: 1 },
    { image: "cloudql-plugin-digitalocean:0.0.1", count: 1 },
    { image: "cloudql-plugin-doppler:0.0.1", count: 1 },
    { image: "cloudql-plugin-entraid:0.0.1", count: 1 },
    { image: "cloudql-plugin-github:0.0.1", count: 1 },
    { image: "cloudql-plugin-googleworkspace:0.0.1", count: 1 },
    { image: "cloudql-plugin-linode:0.0.1", count: 1 },
    { image: "cloudql-plugin-oci:0.0.1", count: 1 },
    { image: "cloudql-plugin-openai:0.0.1", count: 1 },
    { image: "cloudql-plugin-render:0.0.1", count: 1 },
    { image: "alpine:3.20", count: 1 },
    { image: "ubuntu:latest", count: 1 },
    { image: "aquasec/trivy:0.57.1", count: 1 },
    { image: "bitnami/postgresql:latest", count: 1 },
    { image: "cloudql:0.0.1", count: 1 },
    { image: "docker.io/nginx:1.25.0-alpine", count: 1 },
    { image: "docker.mirror.hashicorp.services/golang:alpine", count: 1 },
    { image: "ghcr.io/dexidp/dex:latest", count: 1 },
    { image: "golang:1.23.1-alpine", count: 1 },
  ],
};