import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const useGSAPAnimation = (container:any,section1: string,section2: string,cardsClass : string) => {
  useEffect(() => {
    if (!container.current) return;

    const cards = gsap.utils.toArray(`.${cardsClass}`);
    const totalCards = cards.length;
    const scaleStep = 0.15 / totalCards;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.${section1}`,
        start: "top top",
        end: "+=" + 45 * totalCards + "%",
        scrub: true,
        pin: true,
        pinnedContainer: `.${section1}`,
        pinSpacer: `.${section2}`,
        // pinType: "transform",
        markers: false,
      },
    });

    cards.forEach((card :any, i) => {
      gsap.set(card, {
        y: -(0 * i),
        scale: 1 ,
        zIndex: totalCards - i,
      });

      const otherCards = cards.filter((_, j) => j !== i);
      const nextCard = cards[i + 1];

      if (nextCard) {
        tl.to(card, { opacity: 0, scale: 1.1, y: 35 }, "+=0.5")
          .to(nextCard, { scale: 1, zIndex: "+=1", y: 0 }, "<")
          .to(
            otherCards,
            {
              y: "+=15",
              zIndex: "+=1",
              scale: `+=${scaleStep}`,
            },
            "<"
          )
          .set(card, { zIndex: 0 })
          .to(card, {
            y: -10 * (totalCards - 1),
            scale: 1,
            opacity: 1,
          });
      }
    });

    return () => {
      ScrollTrigger.killAll(); // Clean up ScrollTrigger on unmount
    };
  }, [container]);
};

export default useGSAPAnimation;
