// @ts-nocheck
// import Code from "../Code"
import {
  RiBarcodeBoxLine,
  RiBox1Line,
  RiBracesLine,
  RiCloudLine,
  RiCodeBlock,
  RiCodeBoxLine,
  RiContractUpDownLine,
  RiDatabaseLine,
  RiGitBranchLine,
  RiGitMergeLine,
  RiGroup2Line,
  RiGroupLine,
  RiLink,
  RiLinksLine,
  RiLockStarLine,
  RiOpenSourceLine,
  RiP2pLine,
  RiPlugLine,
  RiRocketLine,
  RiShieldKeyholeLine,
  RiShieldStarLine,
  RiShieldUserLine,
  RiStackLine,
} from "@remixicon/react";
import { Badge } from "../Badge";


export const features = {
  title: "Why OpenComply?",
  subtitle:
    "Govern your entire stack. See what matters. Secure every deployment and change.",
  "bullet-points": [
    {
      "key-title": "Unified Governance",
      icon: RiCloudLine ,
      description:
        "Govern your entire tech stack. Across clouds. Across teams. Full visibility and control.",
    },
    {
      "key-title": "Flexible Policies",
      icon: RiCodeBoxLine ,
      description:
        "Use common policy language across tools. Manage policies in Git.",
    },
    {
      "key-title": "Open and Extensible",
      icon: RiPlugLine,
      description: "Customize with your own controls and write plugins.",
    },
    {
      "key-title": "Engineer-Friendly",
      icon: RiGitMergeLine,
      description: "Integrate with CI/CD and Git. Automate security checks.",
    },
    {
      "key-title": "Enterprise-Grade",
      icon: RiShieldUserLine,
      description: "SSO, RBAC, APIs. Kubernetes-native. Scales seamlessly.",
    },
    {
      "key-title": "Easy to Deploy",
      icon: RiRocketLine ,
      description: "Deploy in minutes with 3 commands.",
    },
  ],
};

export default function CodeExample() {
  return (
    <section
      aria-labelledby="code-example-title"
      className="mx-auto sm:mt-28 sm:pb-28 mt-4 w-full  px-3 flex flex-col justify-center items-center bg-gray-100 dark:bg-gray-900"
    >
      <div className="w-full max-w-6xl 2xl:max-w-7xl mt-12 flex flex-col justify-center items-center">
        <h2
          id="code-example-title"
          className="mt-2 inline-block bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 text-3xl font-bold tracking-tighter text-transparent sm:text-5xl md:text-5xl dark:from-gray-50 dark:to-gray-300"
        >
          {features.title}
        </h2>
        <p className="mt-6 max-w-2xl text-lg text-gray-900 dark:text-gray-400">
          {features.subtitle}
        </p>

        <dl className="sm:mt-24 mt-4 grid grid-cols-3 gap-10">
          {features["bullet-points"].map((item) => (
            <div
              key={item["key-title"]}
              className="col-span-full sm:col-span-2 lg:col-span-1"
            >
              <div className="w-fit rounded-lg p-2 shadow-md shadow-indigo-400/30 ring-1 ring-black/5 dark:shadow-indigo-600/30 dark:ring-white/5">
                <item.icon
                  aria-hidden="true"
                  className="size-6 text-indigo-600 dark:text-indigo-400"
                />
              </div>
              <dt className="mt-6 font-semibold text-gray-900 dark:text-gray-50">
                {item["key-title"]}
              </dt>
              <dd className="mt-2 leading-7 text-gray-600 dark:text-gray-400">
                {item.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
}
