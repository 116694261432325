import React, { useState } from "react";
import { Badge } from "../Badge";
import { Button } from "../Button";
import { Modal } from "@cloudscape-design/components";
import { title } from "process";


export default function Features2() {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
 const features = {
    sectionTitle: "Security and Compliance doesn't have to hurt",
    content: [
      {
        lineNumber: 1,
        text: <>Let’s be honest. Security and compliance tools stand in the way of progress</>,
      },
      {
        lineNumber: 2,
        text: (
          <>
            They’re proprietary, expensive, and rigid. They lack the context and
            capabilities needed for modern tech stacks.
          </>
        ),
      },
      {
        lineNumber: 3,
        text: <>But it doesn’t have to be this way.</>,
      },
      {
        lineNumber: 4,
        text: (
          <>
            That’s why we built <b>opencomply</b>—to make security and
            compliance
            <span
              className=" cursor-pointer rounded-md inline-block w-fit mt-2  "
              onClick={() => {
                setOpen(true);
                setSelected("remarkably");
              }}
            >
              <b>
                <mark className="bg-gradient-to-br from-indigo-900 to-indigo-500 dark:from-indigo-700 dark:to-indigo-400 text-white p-0.5 sm:py-2  mx-1 text-center rounded-md">
                  {" remarkably easy"}
                </mark>
              </b>
            </span>
          </>
        ),
      },
      {
        lineNumber: 5,
        text: (
          <>
            Simplify security and compliance. <b>Build what matters.</b>
          </>
        ),
      },
    ],
  };

  const getContent = (selected: string) => {
    const cards = [];
    var content = {};
    switch (selected) {
      case "remarkably":
        cards.push(
          {
            title: "Easy to Use",
            description:
              "Clean interface, intuitive navigation. Anyone can use it.",
            link: "https://tour.opencomply.io/embed/cm5wy9adt0166010iznzlqg0v?embed_v=2",
          },
          {
            title: "Easy to Adopt",
            description:
              "Get up and running quickly, minimizing disruptions to existing processes.",
            link: "https://tour.opencomply.io/embed/cm5wyjq2t01i01d0hib0buuxk?embed_v=2",
          },
          {
            title: "Easy to Customize",
            description:
              "Tailor frameworks, controls, and processes to your needs.",
            link: "https://tour.opencomply.io/embed/cm5wylkzs01iu1d0h5k6tcbzg?embed_v=2",
          },
          {
            title: "Easy to Integrate",
            description:
              "Works with your existing tools and teams. Write your own plugins. Easy-to-use API available.",
            link: "https://tour.opencomply.io/embed/cm5wzrbb701sw1d0h60jvpiop?embed_v=2",
          }
        );
        content = {
          title: "Security & Compliance Made Easy",
          cards: cards,
        };
        break;

      default:
        break;
    }
    return content;
  };
 <span
   className=" cursor-pointer rounded-md inline-block w-fit  "
   onClick={() => {
     setOpen(true);
     setSelected("remarkably");
   }}
 >
   <b>
     <mark className="bg-gradient-to-br from-indigo-900 to-indigo-500 dark:from-indigo-700 dark:to-indigo-400 text-white p-0.5 sm:py-2  mx-1 text-center rounded-md">
       {" "}
       remarkably easy
     </mark>
   </b>
 </span>;

  return (
    <section
      aria-labelledby="features-title"
      className=" bg-gray-100 dark:bg-gray-900 sm:my-32 my-12"
    >
      {/* <Badge>Security at Scale</Badge> */}
  <div className=" sm:mt-16 mt-4 sm:mb-16 mb-4 py-12 max-w-6xl 2xl:max-w-7xl px-3 sm:px-36  mx-auto rounded-xl flex flex-col justify-center items-center">
    {/* Remove or comment out the entire h2 block: */}
    {
     
    <h2
      id="features-title"
      className="inline-block text-slate-950 dark:text-slate-50 text-center w-full bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 text-4xl font-bold tracking-tighter sm:text-4xl md:text-4xl"
    >
      {/* {features.sectionTitle} */}
    </h2>
    }
        <p className="mt-6   text-xl max-w-4xl  text-center leading-7 text-slate-950 dark:text-slate-50">
         {features.content.sort((a, b) => a.lineNumber - b.lineNumber).map((item) => {
          return (
            <>
              {item.text} <br />
              <br />
            </>
          );
         })}
        </p>
      </div>
   
      <Modal
        header={selected.slice(0, 1).toUpperCase() + selected.slice(1)}
        size="max"
        visible={open}
        onDismiss={() => setOpen(false)}
      >
        <div className="flex flex-col gap-4">
          <div>
            <span className="text-xl font-bold">
              {/* @ts-ignore */}
              {/* {getContent(selected)?.title} */}
            </span>
          </div>
          <div className="flex xl:flex-row flex-col h-full  gap-6 justify-start">
            <div className="flex flex-col 2xl:w-1/6 xl:w-1/3  w-full gap-5 justify-between   items-start ">
              {/* @ts-ignore */}
              {getContent(selected).cards?.map(
                (content: any, index: number) => {
                  return (
                    <>
                      <div
                        onClick={() => setSelectedIndex(index)}
                        className={`rounded-xl w-full cursor-pointer  hover:border-slate-500 border border-transparent      bg-slate-300 sm:p-4 p-2 flex flex-col gap-2 ${selectedIndex == index && "bg-slate-500"}`}
                      >
                        <span className="sm:text-lg text-base font-bold">
                          {content.title}
                        </span>
                        <span className="sm:text-base text-sm hidden sm:inline">
                          {content.description}
                        </span>

                        {/* {index == selectedIndex && (
                        )} */}
                      </div>
                    </>
                  );
                }
              )}
            </div>
            <div className="iframe-div     bg-transparent    min-h-72 w-full      animate-slide-up-fade   ">
              <iframe
                // @ts-ignore
                src={
                  // @ts-ignore

                  getContent(selected)?.cards
                    ? // @ts-ignore
                      getContent(selected).cards[selectedIndex].link
                    : ""
                }
                loading="lazy"
                title="Website - Product Tour"
                allow="clipboard-write"
                frameBorder="0"
                allowFullScreen={true}
                className="iframe-div-frame rounded-xl w-full"
              ></iframe>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
}
